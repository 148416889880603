import React from "react";
import {
  navBar,
  mainBody,
  about,
  repos,
  skills,
  getInTouch,
  experiences,
} from "./editable-stuff/config.js";
import MainBody from "./components/home/MainBody";
import AboutMe from "./components/home/AboutMe";
import Project from "./components/home/Project";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Skills from "./components/home/Skills";
import GetInTouch from "./components/home/GetInTouch";

import Experience from "./components/home/Experience";

const App = () => {
  const titleRef = React.useRef();
  return (
    <div>
      {navBar.show && <Navbar ref={titleRef} />}
      <MainBody
        gradient={mainBody.gradientColors}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        message={mainBody.message}
        icons={mainBody.icons}
        ref={titleRef}
      />
      {about.show && (
        <AboutMe
          heading={about.heading}
          message={about.message}
          link={about.imageLink}
          imgSize={about.imageSize}
          resume={about.resume}
        />
      )}
      {experiences.show && <Experience experiences={experiences} />}
      {skills.show && (
        <Skills
          heading={skills.heading}
          hardSkills={skills.hardSkills}
          softSkills={skills.softSkills}
        />
      )}
      {repos.show && (
        <Project
          heading={repos.heading}
          username={repos.gitHubUsername}
          length={repos.reposLength}
          specfic={repos.specificRepos}
        />
      )}

      <Footer>
        {getInTouch.show && (
          <GetInTouch
            heading={getInTouch.heading}
            message={getInTouch.message}
            email={getInTouch.email}
          />
        )}
      </Footer>
    </div>
  );
  // return (
  //   <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
  //     {navBar.show && <Navbar ref={titleRef} />}
  //     <>
  // {/* <MainBody
  //   gradient={mainBody.gradientColors}
  //   title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
  //   message={mainBody.message}
  //   icons={mainBody.icons}
  //   ref={titleRef}
  // /> */}
  // {about.show && (
  //   <AboutMe
  //     heading={about.heading}
  //     message={about.message}
  //     link={about.imageLink}
  //     imgSize={about.imageSize}
  //     resume={about.resume}
  //   />
  // )}
  //       {/* {experiences.show && <Experience experiences={experiences} />} */}
  // {/* {repos.show && (
  //   <Project
  //     heading={repos.heading}
  //     username={repos.gitHubUsername}
  //     length={repos.reposLength}
  //     specfic={repos.specificRepos}
  //   />
  // )} */}
  // {/* {skills.show && (
  //   <Skills
  //     heading={skills.heading}
  //     hardSkills={skills.hardSkills}
  //     softSkills={skills.softSkills}
  //   />
  // )} */}
  //     </>

  // {/* <Footer>
  //   {getInTouch.show && (
  //     <GetInTouch
  //       heading={getInTouch.heading}
  //       message={getInTouch.message}
  //       email={getInTouch.email}
  //     />
  //   )}
  // </Footer> */}
  //   </BrowserRouter>
  // );
};

export default App;
